import React from 'react'
import Layout from '../components/Layout'
const portafolio = () => {
    return (
        <div>
            <Layout>
            portafolio page
            </Layout>
        </div>
    )
}
export default portafolio